/* eslint-disable react/jsx-filename-extension */
import React, { useState } from "react";
import axios from "axios";
import { API_URI } from "../../config";
import { InputForm } from "./input/index";

import BackImage from "./assets/callback.png";

import {
  ContactContainer,
  TextContainer,
  ImageContainer,
  Picture,
  Title,
  Line,
  Text,
  Button,
  Form,
  TextContent,
  BottomSection,
  FormMessage,
  CheckboxInput,
  CheckboxText,
  CheckboxWrapper,
} from "./styles";

const ContactForm = () => {
  const [inputs, setInputs] = useState({});
  const [succesForm, setSuccesForm] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [showError, setError] = useState(false);

  const handleChange = (event) => {
    setSuccesForm(false);
    setErrorForm(false);
    setError(false);
    const { name } = event.target;
    const { value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputs.receiveEmail) {
      try {
        await axios.post(`${API_URI}/api/home-care/contactMail/`, inputs);
        setSuccesForm(true);
        setInputs({});
      } catch (error) {
        setErrorForm(true);
      }
    } else {
      setError(true);
      e.preventDefault();
    }
  };

  const changeSetCheck = () => {
    setError(false);
    setInputs((values) => ({ ...values, receiveEmail: !inputs.receiveEmail }));
  };

  return (
    <ContactContainer id="callBack">
      <TextContainer>
        <TextContent>
          <Title>REQUEST A CALL BACK</Title>
          <Line />
          <Text>We will call you back within 24 hours</Text>
          <Form onSubmit={handleSubmit}>
            <InputForm
              id="name"
              type="text"
              name="name"
              placeholder="Name"
              errorMsg="Invalid name"
              required
              value={inputs.name || ""}
              onChange={handleChange}
            />
            <InputForm
              id="email"
              type="email"
              name="email"
              placeholder="Email"
              errorMsg="Invalid email"
              required
              value={inputs.email || ""}
              onChange={handleChange}
            />
            <InputForm
              id="phone"
              type="phone"
              name="phone"
              placeholder="Phone"
              errorMsg="Invalid phone"
              required
              value={inputs.phone || ""}
              onChange={handleChange}
            />
            <CheckboxWrapper>
              <CheckboxInput
                type="checkbox"
                checked={inputs.receiveEmail}
                onChange={changeSetCheck}
              />
              <CheckboxText>
                I consent to receive information from Life Home Care
              </CheckboxText>
            </CheckboxWrapper>
            {showError && (
              <FormMessage>
                Please consent to receive information before sending request
              </FormMessage>
            )}
            <BottomSection>
              <Button type="submit"> Send Request </Button>
              {succesForm ? (
                <FormMessage>Information sent successfully</FormMessage>
              ) : (
                errorForm && (
                  <FormMessage>Error. Please try again later</FormMessage>
                )
              )}
            </BottomSection>
          </Form>
        </TextContent>
      </TextContainer>
      <ImageContainer>
        <Picture>
          <img src={BackImage} alt="group of people" />
        </Picture>
      </ImageContainer>
    </ContactContainer>
  );
};

export default ContactForm;
